import { useEffect, useMemo, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import axios from 'axios';
import { PostPaymentOrderResponse } from '@dtp/membership-service-types';
import useSelector from '../redux/typedHooks';

export const useOrder = (orchestrationId: string): PostPaymentOrderResponse | undefined => {
  const [order, setOrder] = useState<PostPaymentOrderResponse>();
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { apimBaseUrl, apimMembershipApi, signalR, apimContentHub } = useSelector((state) => state.application);

  useEffect(() => {
    if (apimBaseUrl && apimContentHub && apimMembershipApi && signalR && !connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${signalR}/${apimMembershipApi}`, {
          headers: {
            'Ocp-Apim-Subscription-Key': apimContentHub,
          },
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [apimContentHub, orchestrationId, apimBaseUrl, apimMembershipApi, signalR, connection]);

  // Function subscribing to signalR
  const startConnection: () => Promise<void> | null = useMemo(() => {
    if (apimContentHub && apimMembershipApi && apimBaseUrl && connection && orchestrationId && signalR) {
      return async () => {
        if (connection) {
          try {
            await connection.start();
            connection.on(orchestrationId, (rawMessage: string) => {
              const message = JSON.parse(rawMessage);
              setOrder(message);
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Connection failed');
          }
          try {
            const res = await axios.get<PostPaymentOrderResponse>(
              `${apimBaseUrl}/${apimMembershipApi}/v2/products/payment/${orchestrationId}`,
              {
                headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
              },
            );
            setOrder(res.data);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }
      };
    }
    return null;
  }, [apimBaseUrl, apimContentHub, apimMembershipApi, connection, orchestrationId, signalR]);

  // Function cleaning up signalR subscription
  const endConnection = useMemo(() => {
    if (connection) {
      return async () => {
        try {
          await connection.stop();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('Connection could not be stopped!');
        }
      };
    }
    return null;
  }, [connection]);

  useEffect(() => {
    if (startConnection) {
      startConnection().then();
      return () => {
        if (endConnection) {
          endConnection().then();
        }
      };
    }
    return () => {};
  }, [startConnection, endConnection]);

  return order;
};
